import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	chevron: {
		width: 24,
		height: 24,
		marginLeft: 16,
		fontSize: 20,
		color: theme.colors.gray[900],
		transition: 'transform 250ms',
	},
	chevronOpened: {
		transform: 'rotate(180deg)',
	},
	menuBody: {
		padding: 0,
		borderRadius: 8,
	},
	menuItem: {
		borderRadius: 0,
	},
	menuIcon: {
		fontSize: 14,
		color: theme.colors.gray[700],
	},
	logoutItem: {
		borderTop: `1px solid ${theme.colors.gray[100]}`,
	},
}));