import {Anchor, Center, Container} from '@mantine/core';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

// Global components
import Text from 'components/core/Text';

// Local styles
import {useStyles} from './styles';

const Menu = ({name, icon, path, active, isParent, onClickMenu}) => {
	const {classes, cx} = useStyles();
	const {t} = useTranslation('translation', {keyPrefix: 'general.menu'});
	// Render
	return (
		<Anchor
			component={path ? Link : null}
			to={path || ''}
			onClick={onClickMenu}>
			<Center className={cx(classes.menuItem, {
				[classes.activeMenu]: active,
				[classes.activeMenuBackground]: active && !isParent,
			})}>
				<Center className={classes.menuIcon}>
					{icon}
				</Center>
				<Container
					fluid
					px={0}
					ml={12}>
					<Text
						size="md"
						className={classes.menuText}>{t(name)}</Text>
				</Container>
			</Center>
		</Anchor>
	);
};

Menu.defaultProps = {
	onClickMenu: () => {},
};

Menu.propTypes = {
	name: PropTypes.string,
	icon: PropTypes.element,
	path: PropTypes.string,
	active: PropTypes.bool,
	isParent: PropTypes.bool,
	onClickMenu: PropTypes.func,
};

export default Menu;
