import {createStyles} from '@mantine/core';

const transition = '250ms ease-in-out';

export const useStyles = createStyles((theme, _, getRef) => ({
	activeMenu: {
		[`& .${getRef('menuIcon')}`]: {
			color: theme.colors.primary[700],
		},
		[`& .${getRef('menuText')}`]: {
			color: theme.colors.primary[700],
		},
	},
	activeMenuBackground: {
		backgroundColor: theme.colors.primary[50],
	},
	menuItem: {
		height: 40,
		padding: '8px 12px',
		boxSizing: 'border-box',
		borderRadius: 6,
		transition,
	},
	menuIcon: {
		ref: getRef('menuIcon'),
		width: 24,
		height: 24,
		fontSize: 20,
		color: theme.colors.gray[500],
		transition,
	},
	menuText: {
		ref: getRef('menuText'),
		fontWeight: 500,
		color: theme.colors.gray[700],
		transition,
	},
}));