const Modal = {
	overlay: {
		backgroundColor: 'rgb(52, 64, 84) !important',
		opacity: '0.7 !important',
	},
	modal: {
		borderRadius: 12,
	},
};

export {Modal};