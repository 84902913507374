import {createStyles} from '@mantine/core';

export const useStyles = createStyles((theme, {color, size, iconSize, borderSize}) => {
	const surroundedCircleSize = {
		width: size - borderSize / 2,
		height: size - borderSize / 2,
	};
	return ({
		container: {
			width: size,
			height: size,
			svg: {
				fontSize: iconSize,
			},
		},
		innerContainer: {
			width: size,
			height: size,
			borderRadius: '50%',
		},
		normal: {
			backgroundColor: theme.colors[color]?.[100] || theme.colors.gray[100],
			color: theme.colors[color]?.[600] || theme.colors.gray[600],
		},
		lightCircle: {
			...surroundedCircleSize,
			boxShadow: `0 0 0 ${borderSize}px ${theme.colors[color]?.[50] || theme.colors.gray[50]}`,
			backgroundColor: theme.colors[color]?.[100] || theme.colors.gray[100],
			color: theme.colors[color]?.[600] || theme.colors.gray[600],
		},
		darkCircle: {
			...surroundedCircleSize,
			boxShadow: `0 0 0 ${borderSize}px ${theme.colors[color]?.[color === 'primary' ? 700 : 600] || theme.colors.gray[700]}`,
			backgroundColor: theme.colors[color]?.[color === 'primary' ? 600 : 500] || theme.colors.gray[600],
			color: theme.white,
		},
		lightSquare: {
			borderRadius: 8,
			backgroundColor: theme.colors[color]?.[50] || theme.colors.gray[50],
			color: theme.colors[color]?.[600] || theme.colors.gray[600],
		},
		midSquare: {
			borderRadius: 8,
			backgroundColor: theme.colors[color]?.[500] || theme.colors.gray[500],
			color: theme.white,
		},
		darkSquare: {
			borderRadius: 8,
			backgroundColor: theme.colors[color]?.[800] || theme.colors.gray[800],
			color: theme.white,
		},
	});
});
