export const inputStyles = (theme, inputPadding) => ({
	// Override default mantine's sizing style based on Untitled UI's sizing.
	// To change font size, please use the `size` prop.
	// Please note mantine's `size` props will not alter input's height because it's overriden by the style below.
	// Also when you use rightSection, please manually set the input's right padding using important style
	input: {
		textOverflow: 'ellipsis',
		minHeight: 'unset',
		height: '100%',
		borderRadius: 8,
		color: theme.colors.gray[900],
		borderColor: theme.colors.gray[300],
		transition: 'box-shadow 250ms',
		'&::placeholder': {
			color: theme.colors.gray[500],
		},
		'&:focus': {
			borderColor: theme.colors.primary[300],
			boxShadow: `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.colors.primary[100]}`,
		},
		'&:disabled': {
			backgroundColor: theme.colors.gray[50],
			color: theme.colors.gray[500],
			'&::placeholder': {
				color: theme.colors.gray[300],
			},
		},
		padding: inputPadding || '0 14px',
	},
	icon: {
		width: 42,
		fontSize: 18,
		color: theme.colors.gray[500],
	},
	withIcon: {
		paddingLeft: 42,
	},
	label: {
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 500,
		color: theme.colors.gray[700],
		marginBottom: 6,
	},
	invalid: {
		borderColor: theme.colors.error[300],
		color: theme.colors.gray[900],
		'&::placeholder': {
			color: theme.colors.gray[500],
		},
	},
	error: {
		marginTop: 6,
		color: theme.colors.error[500],
		fontSize: 14,
		lineHeight: '20px',
	},
});

const NumberInput = theme => ({
	...inputStyles(theme),
	wrapper: {
		height: 44,
	},
});

const Textarea = theme => ({
	...inputStyles(theme),
});

const TextInput = theme => ({
	...inputStyles(theme),
	wrapper: {
		height: 44,
	},
});

const Select = theme => ({
	...inputStyles(theme, '0 30px 0 14px'),
	wrapper: {
		height: 44,
	},
	dropdown: {
		padding: 0,
		borderRadius: 8,
		boxShadow: theme.shadows.lg,
	},
	item: {
		borderRadius: 0,
		padding: '10px 14px',
	},
	hovered: {
		backgroundColor: theme.colors.gray[50],
	},
	selected: {
		backgroundColor: theme.colors.primary[50],
		color: 'unset',
		'&:hover': {
			backgroundColor: theme.colors.gray[50],
		},
	},
});

export {NumberInput, Textarea, TextInput, Select};