import {useState} from 'react';
import {Avatar, Box, Center, Group, Menu} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faKey, faQuestionCircle, faRightFromBracket} from '@fortawesome/pro-regular-svg-icons';

// Global components
import Text from 'components/core/Text';

// Global utils
import notification from 'utils/notification';
import AuthUtils from 'utils/auth';

// Request utils
import {defaultState} from 'request';
import {updatePasswordRequest} from 'request/adminAccount';

// Local components
import ChangePasswordModal from './ChangePasswordModal';

// Local styles
import {useStyles} from './styles';

const ProfileMenu = () => {
	const navigate = useNavigate();
	const {classes, cx, theme} = useStyles();
	const {t} = useTranslation('translation', {keyPrefix: 'general.profileMenu'});
	const [opened, setOpened] = useState(false);
	const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
	const [actionRequestState, setActionRequestState] = useState(defaultState);
	const user = useSelector(({reducerUser}) => reducerUser);

	// Methods
	const submitChangePassword = async (oldPassword, newPassword) => {
		try {
			await updatePasswordRequest({
				oldPassword, newPassword,
			}, setActionRequestState);
			notification.success(t('changePasswordSuccess'));
			setChangePasswordModalOpen(false);
			navigate('/auth', {replace: true});
		} catch (error) {
			if (error.response.status === 401) {
				notification.error(t('wrongOldPasswordSuccess'));
			} else {
				notification.generalError(error);
			}
		}
	};

	// Render
	return (
		<>
			<Menu
				transition="scale"
				onOpen={() => setOpened(true)}
				onClose={() => setOpened(false)}
				classNames={{
					body: classes.menuBody,
					item: classes.menuItem,
				}}
				control={(
					<Group
						spacing={0}
						style={{cursor: 'context-menu'}}>
						<Avatar
							src={user.organizationLogo}
							size={40}
							radius="50%"
							style={{backgroundColor: theme.colors.gray[100]}}
							mr={12} />
						<Box>
							<Text
								size="sm"
								weight={500}
								color={theme.colors.gray[900]}>
								{user.email}
							</Text>
							<Text
								size="sm"
								color={theme.colors.gray[500]}
							>
								{user.organizationName}
							</Text>
						</Box>

						<Center className={cx(classes.chevron, {[classes.chevronOpened]: opened})}>
							<FontAwesomeIcon icon={faChevronDown} />
						</Center>
					</Group>
				)}>
				<Menu.Item onClick={() => setChangePasswordModalOpen(true)}>
					<Group spacing={12}>
						<FontAwesomeIcon
							icon={faKey}
							className={classes.menuIcon} />
						<Text
							size="sm"
							color={theme.colors.gray[700]}>{t('changePassword')}</Text>
					</Group>
				</Menu.Item>
				<Menu.Item>
					<Group spacing={12}>
						<FontAwesomeIcon
							icon={faQuestionCircle}
							className={classes.menuIcon} />
						<Text
							size="sm"
							color={theme.colors.gray[700]}>{t('getHelp')}</Text>
					</Group>
				</Menu.Item>
				<Menu.Item
					className={classes.logoutItem}
					onClick={() => AuthUtils.revokeCurrentSession().catch(() => null).finally(() => {
						navigate('/auth', {replace: true});
					})}>
					<Group spacing={12}>
						<FontAwesomeIcon
							icon={faRightFromBracket}
							className={classes.menuIcon} />
						<Text
							size="sm"
							color={theme.colors.gray[700]}>{t('logout')}</Text>
					</Group>
				</Menu.Item>
			</Menu>
			<ChangePasswordModal
				opened={changePasswordModalOpen}
				loading={actionRequestState.loading}
				onClose={() => setChangePasswordModalOpen(false)}
				onConfirm={submitChangePassword}
			/>
		</>
	);
};


export default ProfileMenu;
