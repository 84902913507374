// Global utils
import AuthUtils from 'utils/auth';

// Request utils
import request, {REQUEST_ENV} from 'request';

export const authCheckRequest = async () => {
	return request({
		method: 'get',
		url: `${REQUEST_ENV.SERVICE_API_HOST}/public/auth/me`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
	});
};
