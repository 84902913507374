import {Button as MantineButton, Loader} from '@mantine/core';
import {AnimatePresence, motion} from 'framer-motion';
import PropTypes from 'prop-types';

// Local styles
import {useStyles} from './styles';

const SIZE_MAPPING = {
	sm: {
		height: 36,
		fontSize: 14,
	},
	md: {
		height: 40,
		fontSize: 14,
	},
	lg: {
		height: 44,
		fontSize: 16,
	},
	xl: {
		height: 48,
		fontSize: 16,
	},
	'2xl': {
		height: 60,
		fontSize: 18,
	},
};

const Button = ({size, destructive, className, loading, disabled, children, ...rest}) => {
	const {classes, cx} = useStyles({
		height: SIZE_MAPPING[size].height,
		fontSize: SIZE_MAPPING[size].fontSize,
		destructive,
	});

	// Render
	return (
		<MantineButton
			className={cx(
				classes.root,
				className,
			)}
			disabled={disabled || loading}
			{...rest}>
			{children}
			<AnimatePresence>
				{loading && (
					<motion.div
						initial={{scale: 0, width: 0}}
						animate={{scale: 1, width: 'auto'}}
						exit={{scale: 0, width: 0}}
						transition={{duration: 0.25}}
					>
						<Loader
							id='buttonLoader'
							className={classes.loader}
							size={SIZE_MAPPING[size].fontSize + 4} />
					</motion.div>
				)}
			</AnimatePresence>
		</MantineButton>
	);
};

Button.defaultProps = {
	size: 'md',
	destructive: false,
	loading: false,
	disabled: false,
};

Button.propTypes = {
	size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
	variant: PropTypes.oneOf(['filled', 'default', 'light']),
	destructive: PropTypes.bool,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
};

export default Button;
