export const ROLES = {
	CREATOR: 'org.creator',
	EDITOR: 'org.editor',
	VIEWER: 'org.viewer',
};

export const ERROR_CODES = {
	EMAIL_NOT_REGISTERED: 'ESP-101',
	WRONG_EMAIL_OR_PASSWORD: 'ESP-102',
	CODE_SESSION_EXPIRED: 'ESP-103',
	USER_NOT_VERIFIED: 'ESP-104',
};

// Based on Untitled UI's colors
export const COLOR_WHEEL = [
	'primary',
	'success',
	'error',
	'blue',
	'orange',
	'rose',
	'warning',
	'purple',
	'blueGray',
	'pink',
	'indigo',
	'blueLight',
];

export const ERROR_VALIDATION = {
	CREATE_ACCOUNT: {
		DATA_INVALID: 'ca_data_invalid',
		EID_EXIST: 'ca_id_exist',
		DUPLICATE_EID: 'ca_duplicate_employee_id',
		DUPLICATE_EMAIL: 'ca_duplicate_email',
	},
	GRANT_EVENT: {
		MISSING_PROPERTY: 'ge_missing_property',
		DATA_INVALID: 'ge_data_invalid',
		ID_NOT_EXIST: 'ge_id_not_exist',
		WRONG_DATA_TYPE: 'ge_wrong_data_type',
		DUPLICATE: 'ge_duplicate',
	},
	RESIGN_EVENT: {
		MISSING_PROPERTY: 're_missing_property',
		DATA_INVALID: 're_data_invalid',
		ID_NOT_EXIST: 're_id_not_exist',
		WRONG_DATA_TYPE: 're_wrong_data_type',
		DUPLICATE: 're_duplicate',
		ALREADY_RESIGN: 're_already_resign',
	},
	SALES_EVENT: {
		MISSING_PROPERTY: 'se_missing_property',
		DATA_INVALID: 'se_data_invalid',
		ID_NOT_EXIST: 'se_id_not_exist',
		WRONG_DATA_TYPE: 'se_wrong_data_type',
		DUPLICATE: 'se_duplicate',
		SAME_RECIPIENT: 'se_same_recipient',
	},
};

export const MIME_TYPES = {
	tsv: 'text/tab-separated-values',
	csv: 'text/csv',
	jpeg: 'image/jpeg',
	jpg: 'image/jpeg',
	png: 'image/png',
};