import AppShell from 'components/core/AppShell';
import RequireAuthRoute from 'components/others/RequireAuthRoute';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDownArrowUp, faGear, faHome, faRectangleList, faSeedling, faUser} from '@fortawesome/pro-regular-svg-icons';

export default [
	{
		path: '/auth',
		element: 'views/Auth/Login',
		hideInMenu: true,
	},
	{
		path: '/',
		element: <RequireAuthRoute><AppShell /></RequireAuthRoute>,
		hideInMenu: true,
		breadcrumb: true,
		children: [
			{
				path: '/',
				name: 'dashboard',
				icon: <FontAwesomeIcon icon={faHome} />,
				element: 'views/Dashboard',
				// authority: ['superadmin'],
			},
			{
				// This path exists in both parent and child in order for the other sibling paths to correctly render breadcrumb
				// When the parent are hidden in menu, it's visible childs will be rendered to the menu list directly without grouping
				path: '/stakeholder',
				name: 'stakeholders',
				icon: <FontAwesomeIcon icon={faUser} />,
				children: [
					{
						path: '/stakeholder',
						name: 'allStakeholders',
						breadcrumb: true,
						hideInMenu: true,
						children: [
							{
								path: '/stakeholder/invite',
								name: 'inviteStakeholders',
								element: 'views/Stakeholders/InviteStakeholders',
								hideInMenu: true,
								breadcrumb: true,
							},
							{
								path: '/stakeholder/:stakeholderId/:accountAwardId',
								element: 'views/Stakeholders/StakeholderEventDetails',
								hideInMenu: true,
								breadcrumb: true,
							},
							{
								path: '/stakeholder/:stakeholderId',
								element: 'views/Stakeholders/StakeholderDetails',
								hideInMenu: true,
								breadcrumb: true,
							},
							{
								path: '/stakeholder/',
								name: 'allStakeholders',
								element: 'views/Stakeholders/StakeholderList',
							},
						],
					},
					{
						path: '/stakeholder/balance',
						name: 'stakeholderBalance',
						element: 'views/Stakeholders/StakeholdersBalance',
						breadcrumb: true,
					},
				],
			},
			{
				path: '/event',
				name: 'events',
				icon: <FontAwesomeIcon icon={faSeedling} />,
				children: [
					{
						path: '/event/grant',
						name: 'grantEvent',
						hideInMenu: true,
						breadcrumb: true,
						children: [
							{
								path: '/event/grant/create',
								name: 'newGrantEvent',
								element: 'views/GrantEvents/CreateGrantEvent',
								hideInMenu: true,
								breadcrumb: true,
							},
							{
								path: '/event/grant/:eventId',
								element: 'views/GrantEvents/GrantEventDetails',
								hideInMenu: true,
								breadcrumb: true,
							},
							{
								path: '/event/grant',
								name: 'grantEvent',
								element: 'views/GrantEvents/GrantEventList',
							},
						],
					},
					{
						path: '/event/resign',
						name: 'resignEvent',
						hideInMenu: true,
						breadcrumb: true,
						children: [
							{
								path: '/event/resign/create',
								name: 'newResignEvent',
								element: 'views/ResignEvents/CreateResignEvent',
								hideInMenu: true,
								breadcrumb: true,
							},
							{
								path: '/event/resign/edit/:eventId',
								name: 'editResignEvent',
								element: 'views/ResignEvents/CreateResignEvent',
								hideInMenu: true,
								breadcrumb: true,
							},
							{
								path: '/event/resign',
								name: 'resignEvent',
								element: 'views/ResignEvents/ResignEventList',
							},
						],
					},
					{
						path: '/event/sales',
						name: 'salesEvent',
						hideInMenu: true,
						breadcrumb: true,
						children: [
							{
								path: '/event/sales/create',
								name: 'newSalesEvent',
								element: 'views/SalesEvents/CreateSalesEvent',
								hideInMenu: true,
								breadcrumb: true,
							},
							{
								path: '/event/sales',
								name: 'salesEvent',
								element: 'views/SalesEvents/SalesEventList',
							},
						],
					},
					{path: '/event', redirect: true},
				],
			},
			{
				path: '/template',
				name: 'templates',
				icon: <FontAwesomeIcon icon={faRectangleList} />,
				children: [
					{
						path: '/template/grant-templates',
						name: 'grantTemplates',
						element: 'views/Templates/GrantTemplates',
						breadcrumb: true,
					},
					{path: '/template', redirect: true},
				],
			},
			// TODO: temporary hides documents page
			// {
			// 	path: '/document',
			// 	name: 'document',
			// 	icon: <FontAwesomeIcon icon={faFolder} />,
			// 	element: 'views/Documents',
			// 	breadcrumb: true,
			// },
			{
				path: '/manage',
				name: 'manage',
				icon: <FontAwesomeIcon icon={faGear} />,
				children: [
					{
						path: '/manage/administrator',
						name: 'manageAdministrator',
						element: 'views/Manage/Administrator',
						breadcrumb: true,
					},
					// TODO: temporary hides organization page
					{
						path: '/manage/organization',
						name: 'manageOrganization',
						element: 'views/Manage/Organization',
						breadcrumb: true,
					},
					{path: '/manage', redirect: true},
				],
			},
			{
				path: '/migrate',
				name: 'migrate',
				icon: <FontAwesomeIcon icon={faArrowDownArrowUp} />,
				element: 'views/Migrate',
				breadcrumb: true,
				hideInMenu: true,
			},
		],
	},
	{redirect: true},
];
