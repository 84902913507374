import {Accordion, Navbar, Stack} from '@mantine/core';
import {useLocation, matchRoutes} from 'react-router-dom';
import {useMediaQuery} from '@mantine/hooks';
import PropTypes from 'prop-types';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';

// Global configs
import appRoutes from 'config/routes';

// Local components
import Menu from './Menu';

// Local styles
import {useStyles} from './styles';

const Sidebar = ({open, onCloseSideBar}) => {
	const isDesktop = useMediaQuery('(min-width: 1024px)');
	const {classes, cx} = useStyles();
	const location = useLocation();
	const authority = ['viewer'];

	// For checking whether parent accordion menu is active
	const matchedRoutes = matchRoutes(appRoutes, location.pathname)?.map(e => e.pathname);

	const renderMenu = routeList => {
		return routeList.map(p => {
			const isActive = p.path === '/' ? location.pathname === '/' : matchedRoutes.includes(p.path);
			const authorized = p.authority ? p.authority.some(a => authority.includes(a)) : true;
			const authorizedChildRoutes = p.children // filter non redirect and authorized child routes
				? p.children.filter(r => !r.redirect && (r.authority ? r.authority.some(a => authority.includes(a)) : true))
				: -1; // -1 means that the route have no child routes and should be rendered if authorized
			// show menu rule only if authorized:
			// 		1. hideInMenu is not true
			// 		2. is not redirection
			// 		3a. it does not have any defined child routes; or
			//		 3b. it contains authorized child routes
			// if the menu is authorized but not shown and it have authorized child routes, show it
			const canShowMenu = !p.hideInMenu && !p?.redirect && (authorizedChildRoutes === -1 || !!authorizedChildRoutes?.length);
			return authorized && (
				canShowMenu ? (
					authorizedChildRoutes === -1 ? (
						// render menu item when it have no child routes
						<Menu
							key={p.name}
							name={p.name}
							icon={p.icon}
							path={p.path}
							active={isActive}
							onClickMenu={!isDesktop ? onCloseSideBar : undefined} />
					) : (
						// render accordion when child route exists
						<Accordion
							key={p.name}
							iconPosition="right"
							// initialItem={isActive ? 0 : undefined} // initially open when it's active
							initialItem={0} // initially expand
							icon={<FontAwesomeIcon icon={faChevronDown} />}
							classNames={{
								item: classes.accordionItem,
								control: classes.accordionControl,
								icon: cx(classes.accordionIcon, {[classes.accordionIconActive]: isActive}),
								contentInner: classes.accordionContentInner,
							}}>
							<Accordion.Item label={(
								<Menu
									isParent
									name={p.name}
									icon={p.icon}
									active={isActive}
									onClickMenu={!isDesktop ? onCloseSideBar : undefined} />
							)}>
								<Stack spacing={4}>
									{renderMenu(authorizedChildRoutes)}
								</Stack>
							</Accordion.Item>
						</Accordion>
					)
				) : !!authorizedChildRoutes.length && renderMenu(authorizedChildRoutes)
			);
		});
	};

	// Render
	return (
		<Navbar
			className={cx(classes.navbar, {[classes.navbarAbsolute]: !isDesktop})}
			hiddenBreakpoint={1023}
			hidden={!open}>
			<Stack
				className={classes.content}
				spacing={4}>
				{renderMenu(appRoutes)}
			</Stack>
		</Navbar>
	);
};

Sidebar.defaultProps = {
	onCloseSideBar: () => {},
};

Sidebar.propTypes = {
	open: PropTypes.bool,
	onCloseSideBar: PropTypes.func,
};

export default Sidebar;
