import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	modalCard: {
		width: 400,
		padding: '24px !important',
	},
	buttons: {
		width: '100%',
	},
	input: {
		paddingRight: 74 + 12,
	},
	rightSection: {
		width: 74,
	},
	fileExtension: {
		width: '100%',
		background: theme.colors.gray[50],
		height: 'calc(100% - 2px)',
		marginRight: 1,
		borderTopRightRadius: 7,
		borderBottomRightRadius: 7,
		borderLeft: `1px solid ${theme.colors.gray[300]}`,
	},
}));