import {Text as MantineText, Title} from '@mantine/core';
import PropTypes from 'prop-types';

// This wrapper component is made to match the UntitledUI's typographies and make the Title component props as similar to Text as possible.

const TITLE_FONT_SIZE_MAPPING = {
	xs: 24,
	sm: 30,
	md: 36,
	lg: 48,
	xl: 60,
	'2xl': 72,
};

const FONT_SIZE_MAPPING = {
	xs: 12,
	sm: 14,
	md: 16,
	lg: 18,
	xl: 20,
};

const LINE_HEIGHT_MAPPING = {
	// Text / Regular
	12: '18px',
	14: '20px',
	16: '24px',
	18: '28px',
	20: '30px',

	// Display / Title
	24: '32px',
	30: '38px',
	36: '44px',
	48: '60px',
	60: '72px',
	72: '90px',
};

const TITLE_ORDER_MAPPING = {
	24: 6,
	30: 5,
	36: 4,
	48: 3,
	60: 2,
	72: 1,
};

const Text = ({size, color, style, title, weight, ...rest}) => {
	const numberedSize = typeof size === 'string'
		? (title ? TITLE_FONT_SIZE_MAPPING[size] || 72 : FONT_SIZE_MAPPING[size] || 16)
		: size;
	const lineHeight = LINE_HEIGHT_MAPPING[numberedSize] || 1.55;
	const Wrapper = title ? Title : MantineText;
	// Render
	return (
		<Wrapper
			style={{
				fontSize: numberedSize,
				lineHeight,
				fontWeight: weight,
				color,
				...style,
			}}
			order={TITLE_ORDER_MAPPING[numberedSize]}
			{...rest}
		/>
	);
};

Text.defaultProps = {
	size: 'md',
	style: {},
	title: false,
};

Text.propTypes = {
	size: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.oneOf([
			...Object.keys(FONT_SIZE_MAPPING),
			...Object.keys(TITLE_FONT_SIZE_MAPPING),
		]),
	]),
	color: PropTypes.string,
	style: PropTypes.object,
	title: PropTypes.bool,
	weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

	// Mantine core props
	align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),

	// Mantine text only props
	component: PropTypes.string,
	lineClamp: PropTypes.number,
	transform: PropTypes.oneOf(['none', 'capitalize', 'uppercase', 'lowercase']),
	underline: PropTypes.bool,

	// Mantine title only props
	order: PropTypes.number,
};

export default Text;
