import {useState} from 'react';
import {Outlet} from 'react-router-dom';
import {AppShell as MantineShell} from '@mantine/core';

// Local components
import Header from './Header';
import Sidebar from './Sidebar';

// Local styles
import {useStyles} from './styles';

const AppShell = () => {
	const {classes} = useStyles();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	// Render
	return (
		<MantineShell
			navbar={(
				<Sidebar
					open={sidebarOpen}
					onCloseSideBar={() => setSidebarOpen(false)}
				/>
			)}
			header={(
				<Header
					open={sidebarOpen}
					onClickHeaderBurger={() => setSidebarOpen(!sidebarOpen)} />
			)}
			classNames={{
				root: classes.root,
				body: classes.body,
				main: classes.main,
			}}
		>
			<Outlet />
		</MantineShell>
	);
};

export default AppShell;
