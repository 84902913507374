import {forwardRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Center, Group, Modal, TextInput} from '@mantine/core';

// Global components
import Text from 'components/core/Text';
import Button from 'components/core/Button';
import {pressEnterConfig} from 'components/core/Input/utils';

// Local styles
import {useStyles} from './styles';

const ExportModal = forwardRef((_, ref) => {
	const {classes, theme} = useStyles();
	const {t} = useTranslation('translation', {keyPrefix: 'general.exportModal'});

	const DEFAULT_CONFIG = {
		title: t('title'),
		subtitle: undefined,
		cancelLabel: t('cancel'),
		confirmLabel: t('confirm'),
		fileExtension: undefined, // If not defined, user are free to write their own extension or it will automatically decided by browser.
		defaultFileName: '',
		request: () => null,
	};

	const [modalConfig, setModalConfig] = useState(DEFAULT_CONFIG);
	const [opened, setOpened] = useState(false);
	const [fileName, setFileName] = useState('');
	const [loading, setLoading] = useState(false);

	// Methods
	const setRef = () => { // Inject functions to ref
		const refObject = {
			export: config => {
				setModalConfig({...DEFAULT_CONFIG, ...config});
				setFileName(config.defaultFileName || '');
				setOpened(true);
			},
		};
		if (ref && ref.hasOwnProperty('current')) {
			ref.current = refObject;
		} else if (typeof ref === 'function') {
			ref(refObject);
		}
	};
	const convertToBase64 = blob => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	};
	const renameFileAndDownload = async fileUrl => {
		const blobFile = await (await fetch(fileUrl)).blob(); // Download and convert to blob
		const fileInstance = (await convertToBase64(blobFile)); // Convert to base64

		const downloadLink = document.createElement('a');
		downloadLink.href = fileInstance;
		downloadLink.download = fileName + (modalConfig?.fileExtension || '');
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	// Event handler methods
	const onClickConfirm = async() => {
		try {
			setLoading(true);
			const result = typeof modalConfig?.request === 'function'
				? await modalConfig?.request()
				:  modalConfig?.request;
			if (result) {
				await renameFileAndDownload(result);
				setOpened(false);
			} else {
				throw new Error('No link to download');
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	// Hooks
	useEffect(() => {
		setRef();
	}, []);

	// Render
	return (
		<Modal
			centered
			opened={opened}
			withCloseButton={false}
			classNames={{
				modal: classes.modalCard,
			}}
			onClose={() => !loading && setOpened(false)}
		>
			<Text
				size="lg"
				weight={500}
				color={theme.colors.gray[900]}>
				{modalConfig?.title}
			</Text>
			{!!modalConfig?.subtitle && (
				<Text
					mt={8}
					size="sm"
					color={theme.colors.gray[500]}>
					{modalConfig?.subtitle}
				</Text>
			)}
			<TextInput
				mt={20}
				size="md"
				label={t('fileName')}
				placeholder={t('fileNamePlaceholder')}
				value={fileName}
				classNames={{
					input: classes.input,
					rightSection: classes.rightSection,
				}}
				rightSection={!!modalConfig?.fileExtension && (
					<Center className={classes.fileExtension}>
						<Text
							size="md"
							color={theme.colors.gray[500]}>
							{modalConfig?.fileExtension}
						</Text>
					</Center>
				)}
				onChange={e => setFileName(e.target.value)}
				{...pressEnterConfig(onClickConfirm)}
			/>
			<Group
				grow
				mt={32}
				className={classes.buttons}>
				<Button
					size="lg"
					variant="default"
					onClick={() => !loading && setOpened(false)}>
					{modalConfig?.cancelLabel}
				</Button>
				<Button
					size="lg"
					loading={loading}
					onClick={onClickConfirm}>
					{modalConfig?.confirmLabel}
				</Button>
			</Group>
		</Modal>
	);
});

export default ExportModal;
