import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	navbar: {
		width: 280,
		borderRightColor: theme.colors.gray[200],
	},
	navbarAbsolute: {
		position: 'absolute',
	},
	content: {
		overflow: 'scroll',
		padding: '32px 16px',
	},
	accordionItem: {
		border: 0,
	},
	accordionControl: {
		padding: 0,
		'&:hover': {
			backgroundColor: 'unset',
		},
	},
	accordionIcon: {
		width: 20,
		marginRight: 10,
		color: theme.colors.gray[500],
	},
	accordionIconActive: {
		color: theme.colors.primary[700],
	},
	accordionContentInner: {
		padding: '8px 0',
	},
}));