import {createStyles} from '@mantine/core';

export const useStyles = createStyles((theme, {height, fontSize, destructive}) => ({
	root: {
		height,
		fontSize,
		padding: '0 14px',
		fontWeight: 500,
		borderRadius: 8,
		boxShadow: theme.shadows.xs,
		transition: '250ms',
		'&::before': {
			content: 'none',
		},
		'&.mantine-Button-filled': {
			backgroundColor: theme.colors[destructive ? 'error' : 'primary'][600],
			color: theme.white,
			'#buttonLoader': {
				stroke: theme.white,
			},
			'&:hover': {
				backgroundColor: theme.colors[destructive ? 'error' : 'primary'][700],
			},
			'&:active': {
				boxShadow: `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.colors[destructive ? 'error' : 'primary'][100]}`,
			},
			'&:disabled': {
				backgroundColor: theme.colors[destructive ? 'error' : 'primary'][200],
				color: theme.white,
				'#buttonLoader': {
					stroke: theme.white,
				},
			},
		},
		'&.mantine-Button-default': {
			backgroundColor: theme.white,
			borderColor: theme.colors[destructive ? 'error' : 'gray'][300],
			color: theme.colors[destructive ? 'error' : 'gray'][700],
			'#buttonLoader': {
				stroke: theme.colors[destructive ? 'error' : 'gray'][700],
			},
			'&:hover': {
				backgroundColor: theme.colors[destructive ? 'error' : 'gray'][50],
				borderColor: theme.colors[destructive ? 'error' : 'gray'][300],
			},
			'&:active': {
				boxShadow: `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.colors[destructive ? 'error' : 'gray'][100]}`,
			},
			'&:disabled': {
				backgroundColor: theme.white,
				borderColor: theme.colors[destructive ? 'error' : 'gray'][200],
				color: theme.colors[destructive ? 'error' : 'gray'][300],
				'#buttonLoader': {
					stroke: theme.colors[destructive ? 'error' : 'gray'][300],
				},
			},
		},
		'&.mantine-Button-light': {
			backgroundColor: theme.colors[destructive ? 'error' : 'primary'][50],
			color: theme.colors[destructive ? 'error' : 'primary'][700],
			'#buttonLoader': {
				stroke: theme.colors[destructive ? 'error' : 'primary'][700],
			},
			'&:hover': {
				backgroundColor: theme.colors[destructive ? 'error' : 'primary'][100],
			},
			'&:active': {
				boxShadow: `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.colors[destructive ? 'error' : 'primary'][100]}`,
			},
			'&:disabled': {
				backgroundColor: theme.colors[destructive ? 'error' : 'primary'][25],
				color: theme.colors[destructive ? 'error' : 'primary'][300],
				'#buttonLoader': {
					stroke: theme.colors[destructive ? 'error' : 'primary'][300],
				},
			},
		},
	},
	loader: {
		paddingLeft: 8,
	},
}));
