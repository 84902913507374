import {DEFAULT_COLORS} from '@mantine/styles/cjs/theme/default-colors';

const primaryColor = {
	25: '#FCFAFF',
	50: '#F3F2FF',
	100: '#E1DFFE',
	200: '#C3BFFD',
	300: '#A39EF9',
	400: '#8A84F3',
	500: '#635CEC',
	600: '#4943CA',
	700: '#332EA9',
	800: '#201D88',
	900: '#141171',
};

export default {
	fontFamily: 'Rubik, sans-serif',
	headings: {
		fontFamily: 'Rubik, sans-serif',
		fontWeight: 500,
		sizes: {
			h1: {fontSize: 72, lineHeight: '90px'},
			h2: {fontSize: 60, lineHeight: '72px'},
			h3: {fontSize: 48, lineHeight: '60px'},
			h4: {fontSize: 36, lineHeight: '44px'},
			h5: {fontSize: 30, lineHeight: '38px'},
			h6: {fontSize: 24, lineHeight: '32px'},
		},
	},
	primaryColor: 'primary',
	// Some colors are included with their mantine default colors as it's overriden & mantine components uses to it's 0-9 index by default
	// Inject primaryColor with 0-9 index values so that default mantine color renders correctly
	colors: {
		primary: {
			...Object.values(primaryColor),
			...primaryColor,
		},
		gray: {
			...DEFAULT_COLORS.gray,
			25: '#FCFCFD',
			50: '#F9FAFB',
			100: '#F2F4F7',
			200: '#EAECF0',
			300: '#D0D5DD',
			400: '#98A2B3',
			500: '#667085',
			600: '#475467',
			700: '#344054',
			800: '#1D2939',
			900: '#101828',
		},
		error: {
			25: '#FFFBFA',
			50: '#FEF3F2',
			100: '#FEE4E2',
			200: '#FECDCA',
			300: '#FDA29B',
			400: '#F97066',
			500: '#F04438',
			600: '#D92D20',
			700: '#B42318',
			800: '#912018',
			900: '#7A271A',
		},
		warning: {
			25: '#FFFCF5',
			50: '#FFFAEB',
			100: '#FEF0C7',
			200: '#FEDF89',
			300: '#FEC84B',
			400: '#FDB022',
			500: '#F79009',
			600: '#DC6803',
			700: '#B54708',
			800: '#93370D',
			900: '#7A2E0E',
		},
		success: {
			25: '#F6FEF9',
			50: '#ECFDF3',
			100: '#D1FADF',
			200: '#A6F4C5',
			300: '#6CE9A6',
			400: '#32D583',
			500: '#12B76A',
			600: '#039855',
			700: '#027A48',
			800: '#05603A',
			900: '#054F31',
		},

		blueGray: {
			25: '#FCFCFD',
			50: '#F8F9FC',
			100: '#EAECF5',
			200: '#D5D9EB',
			300: '#AFB5D9',
			400: '#717BBC',
			500: '#4E5BA6',
			600: '#3E4784',
			700: '#363F72',
			800: '#293056',
			900: '#101323',
		},
		blueLight: {
			25: '#F5FBFF',
			50: '#F0F9FF',
			100: '#E0F2FE',
			200: '#B9E6FE',
			300: '#7CD4FD',
			400: '#36BFFA',
			500: '#0BA5EC',
			600: '#0086C9',
			700: '#026AA2',
			800: '#065986',
			900: '#0B4A6F',
		},
		blue: {
			...DEFAULT_COLORS.blue,
			25: '#F5FAFF',
			50: '#EFF8FF',
			100: '#D1E9FF',
			200: '#B2DDFF',
			300: '#84CAFF',
			400: '#53B1FD',
			500: '#2E90FA',
			600: '#1570EF',
			700: '#175CD3',
			800: '#1849A9',
			900: '#194185',
		},
		indigo: {
			...DEFAULT_COLORS.indigo,
			25: '#F5F8FF',
			50: '#EEF4FF',
			100: '#E0EAFF',
			200: '#C7D7FE',
			300: '#A4BCFD',
			400: '#8098F9',
			500: '#6172F3',
			600: '#444CE7',
			700: '#3538CD',
			800: '#2D31A6',
			900: '#2D3282',
		},
		purple: {
			25: '#FAFAFF',
			50: '#F4F3FF',
			100: '#EBE9FE',
			200: '#D9D6FE',
			300: '#BDB4FE',
			400: '#9B8AFB',
			500: '#7A5AF8',
			600: '#6938EF',
			700: '#5925DC',
			800: '#4A1FB8',
			900: '#3E1C96',
		},
		pink: {
			...DEFAULT_COLORS.pink,
			25: '#FEF6FB',
			50: '#FDF2FA',
			100: '#FCE7F6',
			200: '#FCCEEE',
			300: '#FAA7E0',
			400: '#F670C7',
			500: '#EE46BC',
			600: '#DD2590',
			700: '#C11574',
			800: '#9E165F',
			900: '#851651',
		},
		rose: {
			25: '#FFF5F6',
			50: '#FFF1F3',
			100: '#FFE4E8',
			200: '#FECDD6',
			300: '#FEA3B4',
			400: '#FD6F8E',
			500: '#F63D68',
			600: '#E31B54',
			700: '#C01048',
			800: '#A11043',
			900: '#89123E',
		},
		orange: {
			...DEFAULT_COLORS.orange,
			25: '#FFFAF5',
			50: '#FFF6ED',
			100: '#FFEAD5',
			200: '#FDDCAB',
			300: '#FEB273',
			400: '#FD853A',
			500: '#FB6514',
			600: '#EC4A0A',
			700: '#C4320A',
			800: '#9C2A10',
			900: '#7E2410',
		},
		whiteOverlay: {
			20: 'rgba(255, 255, 255, 0.2)',
			40: 'rgba(255, 255, 255, 0.4)',
			60: 'rgba(255, 255, 255, 0.6)',
			80: 'rgba(255, 255, 255, 0.8)',
			90: 'rgba(255, 255, 255, 0.9)',
		},
		overlay: {
			20: 'rgba(0, 0, 0, 0.2)',
			40: 'rgba(0, 0, 0, 0.4)',
			60: 'rgba(0, 0, 0, 0.6)',
			80: 'rgba(0, 0, 0, 0.8)',
			90: 'rgba(0, 0, 0, 0.9)',
		},
	},
	shadows: {
		xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
		sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
		md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
		lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
		xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
		xl2: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
		xl3: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
		esopqu: '0px 4px 10px rgba(0, 0, 0, 0.05)',
	},
	breakpoints: {
		sm: 640,
		md: 768,
		lg: 1024,
		xl: 1280,
	},
};
