const sharedStyles = theme => ({
	input: {
		border: `1px solid ${theme.colors.gray[300]}`,
		'&:checked': {
			backgroundColor: theme.colors.primary[50],
			borderColor: theme.colors.primary[600],
		},
		'&:disabled': {
			backgroundColor: theme.colors.gray[100],
			borderColor: theme.colors.gray[200],
		},
	},
	icon: {
		color: theme.colors.primary[600],
	},
});

const CheckboxGroup = theme => ({
	...sharedStyles(theme),
});

const Checkbox = theme => ({
	...sharedStyles(theme),
});

export {CheckboxGroup, Checkbox};