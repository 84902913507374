import {useRef, useState} from 'react';
import {TextInput} from '@mantine/core';
import PropTypes from 'prop-types';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons';

// Local styles
import {useStyles} from './styles';

const PasswordInput = ({classNames, ...rest}) => {
	const inputRef = useRef();
	const {classes, theme} = useStyles();
	const [visible, setVisible] = useState(false);

	// Render
	return (
		<TextInput
			ref={inputRef}
			type={visible ? 'text' : 'password'}
			classNames={{
				input: classes.input,
				rightSection: classes.rightSection,
				...classNames,
			}}
			rightSection={(
				<FontAwesomeIcon
					className={classes.icon}
					icon={visible ? faEyeSlash : faEye}
					color={theme.colors.gray[400]}
					onClick={() => {
						inputRef.current.focus();
						setVisible(!visible);
					}} />
			)}
			{...rest}
		/>
	);
};

PasswordInput.defaultProps = {
	classNames: [],
};

PasswordInput.propTypes = {
	classNames: PropTypes.array,
};

export default PasswordInput;
