import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Group, Modal} from '@mantine/core';
import PropTypes from 'prop-types';

// Global components
import Text from 'components/core/Text';
import Button from 'components/core/Button';
import PasswordInput from 'components/core/Input/PasswordInput';
import {pressEnterConfig} from 'components/core/Input/utils';

// Local styles
import {useStyles} from './styles';

const ChangePasswordModal = ({opened, loading, onClose, onConfirm}) => {
	const {classes, theme} = useStyles();
	const {t} = useTranslation('translation', {keyPrefix: 'general.profileMenu.changePasswordModal'});

	const [oldPassword, setOldPassword] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const disabled = !oldPassword?.length || password?.length < 8 || password !== confirmPassword;

	// Methods
	const submitForm = () => {
		!disabled && onConfirm(oldPassword, password);
	};

	// Hooks
	useEffect(() => {
		opened && setOldPassword('');
		opened && setPassword('');
		opened && setConfirmPassword('');
	}, [opened]);

	// Render
	return (
		<Modal
			centered
			opened={opened}
			withCloseButton={false}
			classNames={{
				modal: classes.modalCard,
			}}
			onClose={!loading ? onClose : undefined}
		>
			<Text
				size="lg"
				weight={500}
				color={theme.colors.gray[900]}>
				{t('title')}
			</Text>
			<PasswordInput
				mt={20}
				size="md"
				label={t('oldPassword')}
				placeholder={t('oldPasswordPlaceholder')}
				autoComplete="old-password"
				value={oldPassword}
				onChange={e => setOldPassword(e.target.value)}
				{...pressEnterConfig(submitForm)}
			/>
			<PasswordInput
				mt={20}
				size="md"
				label={t('newPassword')}
				placeholder={t('passwordPlaceholder')}
				autoComplete="new-password"
				value={password}
				onChange={e => setPassword(e.target.value)}
				{...pressEnterConfig(submitForm)}
			/>
			<PasswordInput
				mt={20}
				size="md"
				label={t('confirmPassword')}
				placeholder={t('passwordPlaceholder')}
				autoComplete="new-password"
				value={confirmPassword}
				onChange={e => setConfirmPassword(e.target.value)}
				{...pressEnterConfig(submitForm)}
			/>
			<Group
				grow
				mt={32}
				className={classes.buttons}>
				<Button
					size="lg"
					variant="default"
					onClick={!loading ? onClose : undefined}>
					{t('cancel')}
				</Button>
				<Button
					size="lg"
					loading={loading}
					disabled={disabled}
					onClick={submitForm}>
					{t('confirm')}
				</Button>
			</Group>
		</Modal>
	);
};

ChangePasswordModal.defaultProps = {
	onChangeFormData: () => {},
	onClose: () => {},
	onConfirm: () => {},
};

ChangePasswordModal.propTypes = {
	opened: PropTypes.bool,
	email: PropTypes.string,
	loading: PropTypes.bool,
	onChangeFormData: PropTypes.func,
	onClose: PropTypes.func,
	onConfirm: PropTypes.func,
};

export default ChangePasswordModal;
