import {Checkbox, CheckboxGroup} from './Checkbox';
import {Divider} from './Divider';
import {Menu} from './Menu';
import {Modal} from './Modal';
import {Progress} from './Progress';
import {Tabs} from './Tabs';
import {NumberInput, Textarea, TextInput, Select} from './Inputs';
import {Calendar, DatePicker, RangeCalendar} from './Calendar';

export default {
	Checkbox,
	CheckboxGroup,
	Divider,
	Menu,
	Modal,
	Progress,
	Calendar,
	RangeCalendar,
	Select,
	Tabs,
	Textarea,
	TextInput,
	DatePicker,
	NumberInput,
};
