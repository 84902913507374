import {inputStyles} from './Inputs';

// Override default mantine's sizing style based on Untitled UI's sizing.

// Please note mantine's `size` props will not work here because it's overriden by the style below.
const sharedStyles = theme => ({
	calendarBase: {
		maxWidth: 'unset',
		gap: 0,
		justifyContent: 'center',
		'& > div' : {
			padding: '20px 24px',
			':not(:last-child)': {
				borderRight: `1px solid ${theme.colors.gray[200]}`,
			},
		},
	},
	calendarHeaderLevel: {
		fontSize: 16,
		lineHeight: '24px',
		fontWeight: 500,
		color: theme.colors.gray[700],
	},
	weekdayCell: {
		width: 40,
		height: 40,
		paddingBottom: 0,
	},
	weekday: {
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 500,
		color: theme.colors.gray[700],
	},
	cell: {
		borderTopWidth: 4,
	},
	day: {
		fontSize: 14,
		lineHeight: '20px',
		color: theme.colors.gray[700],
		width: 40,
		height: 40,
		border: 0,
		'&:hover': {
			backgroundColor: `${theme.colors.primary[50]}`,
			fontWeight: 500,
			color: theme.colors.primary[700],
		},
	},
	weekend: {
		color: `${theme.colors.gray[700]} !important`,
	},
	outside: {
		color: 'red !important',
	},
	selected: {
		...firstLastRange(theme, 'selected'),
		fontWeight: 500,
	},
});

const firstLastRange = (theme, type) => {
	return {
		backgroundColor: `${theme.colors.primary[600]} !important`,
		color: `${theme.white} !important`,
		...(type !== 'selected' && {
			'&:before': {
				zIndex: -1,
				content: '""',
				position: 'absolute',
				width: '50%',
				backgroundColor: theme.colors.primary[50],
				top: 0,
				bottom: 0,
				...(type === 'first' ? {right: 0} : {left: 0}),
			},
		}),
	};
};

const Calendar = theme => ({
	...sharedStyles(theme),
});

const RangeCalendar = theme => ({
	...sharedStyles(theme),
	inRange: {
		backgroundColor: `${theme.colors.primary[50]} !important`,
		fontWeight: 500,
		color: `${theme.colors.primary[700]} !important`,
	},
	firstInRange: {
		...firstLastRange(theme, 'first'),
	},
	lastInRange: {
		...firstLastRange(theme, 'last'),
	},
});

const DatePicker = theme => ({
	...inputStyles(theme),
	...sharedStyles(theme),
	wrapper: {
		height: 44,
	},
	dropdown: {
		padding: 0,
	},
	calendarBase: {
		...sharedStyles(theme).calendarBase,
		'& > div' : {
			padding: 8,
			':not(:last-child)': {
				borderRight: `1px solid ${theme.colors.gray[200]}`,
			},
		},
	},
});

export {DatePicker, Calendar, RangeCalendar};