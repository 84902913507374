import {createStyles} from '@mantine/core';

export const useStyles = createStyles(() => ({
	icon: {
		fontSize: 14,
		cursor: 'pointer',
	},
	input: {
		paddingRight: 44,
	},
	rightSection: {
		width: 44,
	},
}));
