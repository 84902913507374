const Tabs = theme => ({
	tabsListWrapper: {
		borderBottom: `1px solid ${theme.colors.gray[200]} !important`,
	},
	tabControl: {
		height: 48,
		color: `${theme.colors.gray[500]} !important`,
	},
	tabActive: {
		color: `${theme.colors.primary[700]} !important`,
		borderBottomColor: `${theme.colors.primary[700]} !important`,
	},
	body: {
		paddingTop: 24,
	},
});

export {Tabs};