import theme from './mantineTheme';

export default {
	LoadingOverlay: {
		overlayColor: 'transparent',
		zIndex: 10,
	},
	Anchor: {
		underline: false,
		weight: 500,
		style: {
			color: theme.colors.primary[700],
		},
	},
	Menu: {
		gutter: 10,
		shadow: theme.shadows.lg,
		radius: 8,
	},
	Checkbox: {
		size: 'xs',
	},
	CheckboxGroup: {
		size: 'xs',
	},
	TextInput: {
		maxLength: 255,
	},
	Textarea: {
		maxLength: 255,
	},
};
