import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Cookies from 'js-cookie';

// Translation JSON Data
import enJson from 'assets/data/i18n-en.json';
import idJson from 'assets/data/i18n-id.json';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: enJson,
		},
		id: {
			translation: idJson,
		},
	},
	lng: Cookies.get('i18n') || 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
});
