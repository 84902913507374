import {createStyles} from '@mantine/core';

export const useStyles = createStyles(() => ({
	modalCard: {
		width: 400,
		padding: '24px !important',
	},
	buttons: {
		width: '100%',
	},
}));