import {Box, Burger, Header as MantineHeader, Image, MediaQuery} from '@mantine/core';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

// Global assets
import SVGLogo from 'assets/icons/esopqu.svg';

// Local components
import ProfileMenu from './ProfileMenu';

// Local styles
import {useStyles} from './styles';

const Header = ({open, onClickHeaderBurger}) => {
	const {classes, theme} = useStyles();
	const navigate = useNavigate();

	// Render
	return (
		<MantineHeader
			className={classes.header}
			height={73}>
			<MediaQuery
				query="(min-width: 1024px)"
				styles={{display: 'none'}}>
				<Burger
					opened={open}
					onClick={onClickHeaderBurger}
					size="md"
					color={theme.colors.black}
					ml={16}
				/>
			</MediaQuery>
			<Box className={classes.content}>
				<Image
					src={SVGLogo}
					width={90}
					height={32}
					style={{cursor: 'pointer'}}
					onClick={() => navigate('/')} />
				<ProfileMenu />
			</Box>
		</MantineHeader>
	);
};

Header.defaultProps = {
	onClickHeaderBurger: () => {},
};

Header.propTypes = {
	open: PropTypes.bool,
	onClickHeaderBurger: PropTypes.func,
};

export default Header;
