import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	header: {
		display: 'flex',
		alignItems: 'center',
		borderBottomColor: theme.colors.gray[200],
	},
	content: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 32px',

		[theme.fn.smallerThan('md')]: {
			padding: '0 16px',
		},
	},
}));