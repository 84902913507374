import {get, isString, isFunction, isObject} from 'lodash';
import axios from 'axios';

// Local utils
import crashLogger from 'utils/logger';
import {ERROR_CODES} from '../utils/constants';

export const REQUEST_ENV = {
	SERVICE_API_HOST: process.env.REACT_APP_API_HOST,
};

export const defaultState = {
	loading: false,
	error: null,
};

axios.interceptors.response.use(response => response, error => {
	const errorCode = error?.response?.data?.errorCode;
	const skipLogging =
		error.response.status === 401 ||
		[
			ERROR_CODES.EMAIL_NOT_REGISTERED,
			ERROR_CODES.WRONG_EMAIL_OR_PASSWORD,
			ERROR_CODES.CODE_SESSION_EXPIRED,
			ERROR_CODES.USER_NOT_VERIFIED,
		].includes(errorCode);
	if (!skipLogging) {
		crashLogger.error(error, 'error');
	}
	return Promise.reject(error);
});

const request = async (param1, param2) => {
	const isFunctionBasedComponent = isFunction(param1)
		? param1
		: isFunction(param2)
			? param2
			: null;
	const isClassBasedComponent =
    (isString(get(param1, 'key')) &&
      typeof get(param1, 'component') === 'object') ||
    (isString(get(param2, 'key')) &&
      typeof get(param2, 'component') === 'object');
	const axiosBody =
    isString(get(param1, 'url')) && isString(get(param1, 'method'))
    	? param1
    	: isString(get(param2, 'url')) && isString(get(param2, 'method'))
    		? param2
    		: null;
	try {
		if (isFunctionBasedComponent) {
			isFunctionBasedComponent({
				loading: true,
				error: null,
			});
		} else if (isClassBasedComponent) {
			// TODO: class based component stateful request
		}
		const response =
      isObject(axiosBody) &&
      (
      	await axios.request({
      		...axiosBody,
      		timeout: 15000,
      	})
      ).data;
		if (isFunctionBasedComponent) {
			isFunctionBasedComponent({
				loading: false,
				error: null,
			});
		} else if (isClassBasedComponent) {
			// TODO: class based component stateful request
		}
		return response;
	} catch (error) {
		if (isFunctionBasedComponent) {
			isFunctionBasedComponent({
				loading: false,
				error: error,
			});
		} else if (isClassBasedComponent) {
			// TODO: class based component stateful request
		}
		throw error;
	}
};

export default request;
